import { render, staticRenderFns } from "./discount_pc.vue?vue&type=template&id=117c3c2a&scoped=true&"
var script = {}
import style0 from "./discount_pc.vue?vue&type=style&index=0&id=117c3c2a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "117c3c2a",
  null
  
)

export default component.exports